/* eslint-disable react-hooks/rules-of-hooks */
import BigNumber from 'bignumber.js';

import { ZERO } from 'lib/consts';

interface Params {
  value: string;
  exchangeRate?: string | null;
  accuracy?: number;
  accuracyUsd?: number;
  decimals?: string | null;
  derivedUSD?: string | null;
}

export default function getCurrencyValue({
  value,
  accuracy,
  accuracyUsd,
  decimals,
  exchangeRate,
  derivedUSD,
}: Params) {
  if (!value) {
    return { valueStr: '0', usd: undefined, usdBn: ZERO };
  }

  const valueCurr = BigNumber(value).div(
    BigNumber(10 ** Number(decimals ?? '18')),
  );
  const valueResult = accuracy !== undefined ?
    valueCurr.dp(accuracy).toFormat() :
    valueCurr.toFormat();

  let usdResult: string | undefined;
  let usdBn = ZERO;

  if (exchangeRate && derivedUSD) {
    const exchangeRateBn = new BigNumber(
      Number(derivedUSD).toFixed(2),
    );
    usdBn = valueCurr.times(exchangeRateBn);
    if (accuracyUsd !== undefined && !usdBn.isEqualTo(0)) {
      const usdBnDp = usdBn.dp(accuracyUsd);
      usdResult = usdBnDp.isEqualTo(0) ?
        usdBn.precision(accuracyUsd).toFormat() :
        usdBnDp.toFormat();
    } else {
      usdResult = usdBn.toFormat();
    }
  }

  return { valueStr: valueResult, usd: usdResult, usdBn };
}
