import getCurrencyValue from 'lib/getCurrencyValue';

import useGetPrice from './useGetPrice';

type Params = {
  value: string;
  exchangeRate?: string | null;
  accuracy?: number;
  accuracyUsd?: number;
  decimals?: string | null;
}

export default function useGetCurrencyValue({
  value,
  accuracy,
  accuracyUsd,
  decimals,
  exchangeRate,
}: Params) {
  const { derivedUSD } = useGetPrice();
  const fn = (props: Params) => getCurrencyValue({ ...props, derivedUSD });
  return { ...getCurrencyValue({ value, decimals, accuracy, accuracyUsd, exchangeRate, derivedUSD }), getCurrencyValue: fn };
}
