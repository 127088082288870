import type { QueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';

export default function useGetPrice(options?: QueryOptions) {
  const query = useQuery(
    gql`
      query token {
        tokens(
          where: { id_in: ["0xe81ca6eb09bd7d28ebc6362cd531d44528dcbf0d"] }
          orderBy: tradeVolumeUSD
          orderDirection: desc
        ) {
          id
          symbol
          name
          derivedETH
          derivedUSD
          tradeVolumeUSD
          totalTransactions
          totalLiquidity
        }
      }
    `,
    options,
  );
  return { ...query, derivedUSD: query.data?.tokens?.[0]?.derivedUSD };
}
